<template>
  <div class="account">
    <h2 class="b tc" :class="{isPad: app.isPad}">用户基本信息</h2>
    <div class="section fr">
      <img src="../assets/images/user_id.png" alt="">
      <span class="title">用户ID</span>
      <span>{{$store.state.userinfo.user_id || userInfo.user_id}}</span>
    </div>
    <div class="section fr">
      <img src="../assets/images/user_name.png" alt="">
      <span class="title">用户姓名</span>
      <span>{{$store.state.userinfo.nickname || userInfo.nickname}}</span>
    </div>
    <div class="section fr">
      <img src="../assets/images/user_school.png" alt="">
      <span class="title">所属学校</span>
      <span>{{$store.state.userinfo.school_name || userInfo.school_name}}</span>
    </div>
    <div class="section fr">
      <img src="../assets/images/user_class.png" alt="">
      <span class="title">所属班级</span>
      <span>{{$store.state.userinfo.class_name || userInfo.class_name}}</span>
    </div>
    <div class="section fr">
      <img src="../assets/images/user_phone.png" alt="">
      <span class="title">手机号码</span>
      <span>{{$store.state.userinfo.mobile || userInfo.mobile}}</span>
    </div>
  </div>
</template>

<script>
import {getUserinfo} from 'utils/util'

export default {
  inject: ['app'],
  data() {
    return {
      userInfo: {},
      count: 3,
      startTime: null
    }
  },
  mounted () {
    let obj = getUserinfo()
    if (obj) {
      obj = JSON.parse(obj)
      this.userInfo = obj
    }
  },
}
</script>

<style lang="scss" scoped>
.account {
  background: white;
  margin: 30px 0 25px 0;
  padding: 30px 30px 45px 30px;
  border-radius: 18px;
  h2 {
    font-size: 28px;
    color: #333;
    &.isPad {
      font-size: 24px;
    }
  }
  .section {
    margin-top: 30px;
    color: #333;
    font-size: 16px;
    align-items: center;
    img {
      width: 20px;
    }
    .title {
      color: #999;
      margin-left: 20px;
      margin-right: 55px;
      display: inline-block;
      width: 70px;
    }
  }
}
</style>